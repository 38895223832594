<template>
  <div>
    <Tabs v-model="tab" @setTab="setTab">
      <Tab v-for="item in tabItems" :key="item">{{ item }}</Tab>
    </Tabs>

    <TabsItems v-model="tab">
      <component :is="componentTabItem" :client-id="clientId" :items="itemsList"
        :is-custom-items="isCustomItems" :nature-operation-id="natureOperationId"
        :stock-required="stockRequired" :stock-id="stockId" :no-destroy="noDestroy"
        :disabled-new-item-button="disabledNewItemButton"
        :price-list-id="priceListId" :is-wholesale="isWholesale"
        :is-replacement="isReplacement"
        @addItem="addItem" @editItem="editItem" @removeItem="removeItem"  />
    </TabsItems>
  </div>
</template>

<script>
import {
  Tabs,
  Tab,
  TabsItems
} from '@/components/vuetify';
import ItemsDataListItems from './ItemsDataListItems';
import ItemsDataListTaxes from './ItemsDataListTaxes';

const TAB_COMPONENT = [
  ItemsDataListItems,
  ItemsDataListItems,
  ItemsDataListTaxes,
];

export default {
  name: 'ItemsDataList',
  components: {
    Tabs,
    Tab,
    TabsItems,
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    itemsReplacement: {
      type: Array,
      default: () => []
    },
    priceListId: {
      type: String,
      default: null
    },
    clientId: {
      type: String,
      required: true
    },
    natureOperationId: {
      type: String,
      required: true
    },
    stockId: {
      type: String,
      default: ''
    },
    stockRequired: {
      type: Boolean,
      default: true
    },
    noDestroy: {
      type: Boolean,
      default: false
    },
    disabledNewItemButton: {
      type: Boolean,
      default: false
    },
    isWholesale: {
      type: Boolean,
      default: false
    },
    isCustomItems: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      tab: null,
      componentTabItem: ItemsDataListItems,
      propsComponents: {}
    };
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.items.dataList;
    },
    tabItems() {
      const tabs = ['Itens do Pedido', 'Impostos'];

      if (this.$route?.meta.type === this.$enums.typePageOrders.ORDER_SALE) {
        tabs.splice(1, 0, 'Troca');
      }

      return tabs;
    },
    isReplacement () {
      return this.tab === 1;
    },
    itemsList() {
      return this.isReplacement ? this.itemsReplacement : this.items;
    },
  },
  methods: {
    setTab (tab) {
      this.tab = tab;
      this.componentTabItem = TAB_COMPONENT[tab];
    },
    addItem (data) {
      this.$emit('addItem', { ...data, is_replacement: this.isReplacement });
    },
    editItem (data) {
      this.$emit('editItem', data);
    },
    removeItem (data) {
      this.$emit('removeItem', data);
    }
  }
};
</script>

<style>

</style>
