import { getCurrentPeriod } from '@/utils';
import { orderTypes, freights } from '@/utils/enums';

class Order {
  get model () {
    return {
      type: orderTypes.ORDER_SALE,
      client_id: '',
      seller_id: '',
      type_installment_id: '',
      stock_id: '',
      price_list_id: '',
      payment_method_id: '',
      nature_operation_id: '',
      order_number: '',
      solicitation_number: '',
      presence_indicator: false,
      invoice_number: '',
      emission_date: getCurrentPeriod().currentDateEN,
      prevision_date: '',
      delivery_date: '',
      is_consumer_final: false,
      is_replacement: false,
      is_wholesale: false,
      freight: freights.NO_FREIGHT,
      total_freight: 0,
      total_expense: 0,
      include_total_in_freight: false,
      items: [],
      items_replacement: [],
      deleted_items: [],
      deleted_items_replacement: [],
      total_weight_liquid: 0,
      total_weight_gross: 0,
      total_products: 0,
      total_taxes: 0,
      total_discount_value: 0,
      total_discount_percentage: 0,
      total_value_replacement: 0,
      total_discount_general: 0,
      total_order: 0,
      is_delivered: false,
      transporter: {
        register_id: '',
        vehicle_id: '',
        specie: '',
        brand: '',
        cte: '',
        quantity: 0,
        gross_weight: 0,
        liquid_weight: 0,
        uf: '',
        rntc: '',
        plate: '',
      },
      devolution_histories: [],
      payments: [],
      generate_financial: true,
      is_custom_items: false,
    };
  }
  get validations () {
    return {};
  }
  get validationsErrors () {
    return {};
  }
}

export default new Order();
