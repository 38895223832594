import { getHour, getCurrentPeriod } from '@/utils';

class ProductInvoice {
  get model () {
    return {
      emitter_id: '',
      order_id: '',
      nature_operation_id: '',
      invoice_setting_id: '',
      transporter_id: '',
      version: '',
      uf_code: '',
      nf_code: '',
      model: '',
      series: '',
      nf_number: '',
      emission_date: getCurrentPeriod().currentDateEN,
      input_output_emission_hour: getHour(),
      operation_type: '',
      destination_location_identifier: '',
      municipality_code: '',
      printer_type: '',
      emission_type: '',
      verification_digit: '',
      environment: '',
      emission_finality: '',
      final_consumer: false,
      presence_indicator: '',
      emission_process: '',
      process_version: '',
      modality_freight: '',
      volume_quantities: '',
      volume_species: '',
      volume_brands: '',
      volume_numbering: '',
      liquid_weight: 0,
      gross_weight: 0,
      additional_tax_information: '',
      additional_information: '',
      technical_cnpj: '',
      technical_contact: '',
      technical_email: '',
      technical_phone: '',
      application_version: '',
      key_nfe: '',
      receipt_date: getCurrentPeriod().currentDateEN,
      input_output_receipt_hour: getHour(),
      protocol_number: '',
      digest_value: '',
      status_code: '',
      reason: '',
      xml: '',
      status: '',
      nature_operation: {
        id: '',
        name: '',
      },
      emitter: {
        name: '',
        address: {
          street: null,
          number: null,
          complement: null,
          neighborhood: null,
        }
      },
      client: {
        id: '',
        client_id: '',
        address: {}
      },
      bill: {
        liquid_value: 0,
        original_value: 0,
        duplicates: [],
        deleted_duplicates: [],
        payment_method: {
          name: ''
        }
      },
      transporter: {
        id: '',
        transporter_id: '',
        vehicle_id: '',
        person_type: '',
        name: '',
        cpf: '',
        cnpj: '',
        ie: '',
        freight_modality: '',
        zip_code: '',
        country: '',
        uf: '',
        city: '',
        neighborhood: '',
        street: '',
        number: '',
        complement: '',
        vehicle_plate: '',
        vehicle_uf: '',
        vehicle_rntc: '',
      },
      transport: {
        liquid_weight: 0,
        gross_weight: 0,
        brand: 0,
        specie: 0,
        quantity: 0,
        freight_include: 0,
        freight_value: 0,
        other_expenses: 0,
        safe_value: 0,
        cte: 0,
        number: 0,
      },
      total: {
        icms_base_calculate: 0,
        icms_value: 0,
        icms_deson_value: 0,
        fund_poverty_value: 0,
        icms_st_base_calculate: 0,
        icms_st_value: 0,
        tax_substitute_value: 0,
        tax_substitute_previously_value: 0,
        products_value: 0,
        freight_value: 0,
        safe_value: 0,
        discount_value: 0,
        ii_value: 0,
        ipi_value: 0,
        ipi_returned_value: 0,
        pis_value: 0,
        cofins_value: 0,
        tax_approximates_value: 0,
        tax_value: 0,
        other_expenses: 0,
        nf_value: 0,
      },
      items: [],
      itemsReplacement: [],
      deleted_items: [],
      payments: []
    };
  }
  get validations () {
    return {};
  }
  get validationsErrors () {
    return {};
  }
}

export default new ProductInvoice();
