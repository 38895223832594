<template>
  <div>
    <ModalCardDefault :title="'Adicionar Produto'" @close="close">
      <v-form v-model="validFormProduct" ref="validFormProduct" lazy-validation>
        <ExpansionPanel v-model="panel" :title="'Informações do Produto'"
          :icon="$icons.box" readonly no-header>
          <Row class="mt-5">
            <Col md="2">
              <TextField v-model="localForm.item_number"
                placeholder="N° Item" label="N° Item" />
            </Col>
            <Col md="4">
              <div class="d-flex">
                <div>
                  <AutoComplete v-model="localForm.product" placeholder="Selecione o Produto"
                    label="Selecione o Produto" :rules="[rules.required]" return-object
                    domain="product" resultDomain="products" item-value-data-serve="id"
                    item-text-data-serve="name" :search-by="['name', 'code', 'bar_code']"
                    title="Pesquise por: Nome, Código ou Código de Barras"
                    @change="changeProduct($event)" condition-or style="width: 300px !important" />
                </div>
                <div class="mt-1 ml-2" v-if="productSelected.has_variation">
                  <ButtonIcon color="secondary" x-small :icon="$icons.edit" :tooltip="'Alterar Variação'"
                    @click="dialogProductVariations = true"   />
                </div>
              </div>
            </Col>
            <Col md="4">
              <TextField v-model="localForm.name" placeholder="Nome do Produto"
                label="Nome do Produto" />
            </Col>
            <Col md="2">
              <TextField v-model="localForm.code" placeholder="Cod. Produto"
                label="Código" />
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <Select v-model="localForm.unit_measure" placeholder="Unidade. Medida"
                label="Uni. Medida" return-object :items="unitMeasures"  />
            </Col>
            <Col md="2">
              <TextFieldMoney v-model="localForm.unit_value"
                @input="setDiscountAndWeight('value')" placeholder="Vlr. Unitário" label="Vlr. Unitário" />
            </Col>
            <Col md="2">
              <Select v-model="localForm.ncm" placeholder="NCM"
                label="NCM" :items="ncms" return-object />
            </Col>
            <Col md="2">
              <Select v-model="localForm.cest" placeholder="CEST"
                label="CEST" :items="cests" return-object clearable />
            </Col>
            <Col md="2">
              <TextFieldInteger v-model="localForm.total_weight_gross"
                placeholder="Peso (gramas)" label="Peso (gramas)" />
            </Col>
            <Col md="2">
              <TextFieldPercent v-model="localForm.quantity"
                @input="setDiscountAndWeight('value')" placeholder="Quantidade"
                :length="1000" label="Quantidade" />
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <TextFieldMoney v-model="localForm.sub_total"
                placeholder="Sub Total" label="Sub Total" />
            </Col>
            <Col md="2">
              <TextFieldMoney v-model="localForm.total_value"
                placeholder="Vlr. Total" label="Vlr. Total" />
            </Col>
            <Col md="2">
              <Select v-model="localForm.nature_operation_id" placeholder="Natureza Operação"
                label="Nat. Operação" :items="natureOperations"
                :rules="[rules.required]" />
            </Col>
            <Col md="2">
              <Select v-model="productVariationsStockSelected" placeholder="Estoque"
                return-object label="Estoque" :items="productVariationsStocks"
                :rules="[rules.required]"
                @change="changeStock" />
            </Col>
            <Col md="2">
              <TextField v-model="localForm.order_number"
                placeholder="N° Ordem" label="N° Ordem" />
            </Col>
            <Col md="2">
              <TextField v-model="localForm.order_item_number"
              placeholder="N° Item" label="N° Item" />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <TextField v-model="localForm.observation"
              placeholder="Observações" label="Observações" />
            </Col>
          </Row>
        </ExpansionPanel>
      </v-form>

      <div slot="actions" class="d-flex justify-end" style="width: 100%">
        <Button :label="'Inserir'"  @click="addItem"
          class="mr-3" rounded color="secondary" :loading="loadingTaxes" />
      </div>
    </ModalCardDefault>

    <Dialog :dialog="dialogProductVariations" :max-width="900">
      <ItemsDataListItemsProductVariation :product="productSelected" slot="content"
        @setProductSelected="setProductSelected" @update:dialogModal="dialogProductVariations = $event" />
    </Dialog>
  </div>
</template>

<script>
import axios from '@/service';
import ModalCardDefault from '@/components/ModalCardDefault';
import {
  ExpansionPanel,
  Button,
  Row,
  Col,
  Select,
  TextField,
  TextFieldMoney,
  TextFieldPercent,
  TextFieldInteger,
  Dialog,
  AutoComplete,
  ButtonIcon,
} from '@/components/vuetify';
import ItemsDataListItemsProductVariation from './ItemsDataListItemsProductVariation';
import { required } from '@/utils/rules';
import { transformArrayToListSelect } from '@/utils';
import SetDiscountAndWeightMixin from './mixins/SetDiscountAndWeightMixin';
import CalculateItemTaxesMixin from './mixins/CalculateItemTaxesMixin';

export default {
  name: 'ItemsDataListItemsProduct',
  components: {
    ModalCardDefault,
    ExpansionPanel,
    Button,
    Row,
    Col,
    Select, TextField,
    TextFieldMoney,
    TextFieldPercent,
    TextFieldInteger,
    Dialog,
    ItemsDataListItemsProductVariation,
    AutoComplete,
    ButtonIcon
  },
  props: {
    priceListId: {
      type: String,
      default: null
    },
    clientId: {
      type: String,
      required: true
    },
    natureOperationId: {
      type: String,
      required: true
    },
    stockId: {
      type: String,
      default: ''
    },
    qtdItems: {
      type: Number,
      default: 0
    },
    searchProduct: {
      type: Object,
      default: null
    },
    isWholesale: {
      type: Boolean,
      default: false
    },
    isReplacement: {
      type: Boolean,
      default: false
    },
  },
  created() {
    window.addEventListener('keydown', this.actionListener);
  },
  data () {
    return {
      panel: [0],
      rules: {
        required: required,
      },
      validFormProduct: false,
      dialogProductVariations: false,
      loadingTaxes: false,
      productSelected: {},
      localForm: {
        item_number: this.qtdItems + 1,
        product_variation_id: '',
        product_variation_stock_id: '',
        nature_operation_id: this.natureOperationId,
        quantity: 1,
        stock_id: this.stockId,
        stock: {},
        discount: 0,
        discount_value: 0,
      },
      natureOperations: [],
      unitMeasures: [],
      productVariationsStocks: [],
      productVariationsStockSelected: {},
      ncms: [],
      cests: [],
    };
  },
  computed: {
    l () {
      return this.$locales.pt.products.list.modalVariations;
    },
  },
  mixins: [SetDiscountAndWeightMixin, CalculateItemTaxesMixin],
  methods: {
    actionListener(event) {
      if (event.key === 'Escape') {
        this.close();
      } else if (event.key === 'Enter') {
        this.addItem();
      }
    },
    async getListSelects () {
      axios.post('get_list_selects_orders_product_invoices').then(({ data }) => {
        this.natureOperations = transformArrayToListSelect(data.nature_operations);
        this.unitMeasures = transformArrayToListSelect(data.unit_measures);
        this.ncms = transformArrayToListSelect(data.ncms);
        this.cests = transformArrayToListSelect(data.cests);
      }).catch(() => {
      });
    },
    changeProduct (item) {
      this.productSelected = item;
      this.localForm.product = item;

      if (item.has_variation) {
        this.dialogProductVariations = true;
      } else {
        item.product_variation = item.product_variations[0];
        this.setProductSelected(item);
      }
    },
    setProductSelected(product) {
      const { product_variation } = product;
      let productPrice = this.isWholesale ? product_variation.price_wholesale : product_variation.price;

      const mountItemObj = (item) => {
        return {
          text: item?.name,
          value: item?.id,
          item_value: item?.value
        };
      };

      const setSubTotal = () => {
        return (productPrice * this.localForm.quantity);
      };

      this.productVariationsStocks = product.product_variation.product_variation_stocks.map((item) => {
        return {
          value: item.id,
          text: item.stock.name,
          stockId: item.stock_id
        };
      });
      this.productVariationsStockSelected = this.productVariationsStocks.find((item) => item.stockId === this.stockId);

      const setLocalForm = () => {
        this.localForm = {
          product,
          product_id: product.id,
          product_variation_id: product_variation.id,
          product_variation_stock_id: this.productVariationsStockSelected?.value || '',
          nature_operation_id: this.natureOperationId,
          price_list_id: product_variation.price_list_id,
          item_number: this.qtdItems + 1,
          code: product_variation.code,
          bar_code: product_variation.bar_code,
          reference: product.reference,
          name: product_variation.name,
          unit_measure: mountItemObj(product.unit_measure),
          quantity: this.localForm.quantity,
          unit_value: productPrice,
          discount_percentage: 0,
          discount_value: this.localForm.discount_value,
          sub_total: subTotal,
          total_value: (subTotal - this.localForm.discount_value),
          ncm: mountItemObj(product.ncm),
          cest: mountItemObj(product.cest),
          weight_liquid_unit: product_variation.liquid_weight,
          weight_gross_unit: product_variation.gross_weight,
          total_weight_liquid: parseFloat(product_variation.liquid_weight) * parseFloat(this.localForm.quantity),
          total_weight_gross: parseFloat(product_variation.gross_weight) * parseFloat(this.localForm.quantity),
        };
      };

      let subTotal = setSubTotal();

      if (this.priceListId) {
        this.$api.products.getPriceByPriceListIdProductVariation({
          product_variation_id: product_variation.id, price_list_id:
          this.priceListId
        }).then(({ data: { final_value, no_price_list } }) => {
          productPrice = final_value;
          no_price_list ? product_variation.price_list_id = null : product_variation.price_list_id = this.priceListId;
          subTotal = setSubTotal();
          setLocalForm();
        });
      } else {
        setLocalForm();
      }
    },
    async addItem () {
      if (this.$refs?.validFormProduct?.validate()) {
        try {
          this.loadingTaxes = true;
          this.localForm = await this.calculateItemTaxes(this.localForm, this.clientId);

          if (this.localForm.movement_stock && !this.localForm.has_stock) {
            this.$noty.error('Produto não tem estoque suficiente para a quantidade informada');
            this.localForm = {
              ...this.localForm,
              unit_measure: { text: this.productSelected.unit_measure.name, value: this.productSelected.unit_measure.id, item_value: this.productSelected.unit_measure.name },
              ncm: { text: this.productSelected.ncm.name, value: this.productSelected.ncm.id },
              cest: { text: this.productSelected.cest?.name, value: this.productSelected.cest?.id }
            };
            this.loadingTaxes = false;

            return;
          }

          window.removeEventListener('keydown', this.actionListener);
          this.$emit('addItem', this.localForm);
          this.resetLocalForm();
          this.loadingTaxes = false;
        } catch (err) {
          this.loadingTaxes = false;
          this.$noty.error('Erro ao adicionar o item');
        }

      }
    },
    resetLocalForm () {
      this.localForm = {
        item_number: this.qtdItems + 1,
        product_variation_id: '',
        product_variation_stock_id: '',
        stock_id: this.stockId,
        stock: {},
        product: {},
        discount: 0,
        quantity: 1,
        nature_operation_id: this.natureOperationId,
        discount_value: 0,
      };
    },
    changeStock (item) {
      this.productVariationsStockSelected = item;
      this.localForm.product_variation_stock_id = item.value;
    },
    close() {
      window.removeEventListener('keydown', this.actionListener);
      this.resetLocalForm();
      this.$emit('close', false);
    }
  },
  async mounted () {
    await this.getListSelects();

    if (this.searchProduct) {
      this.setProductSelected(this.searchProduct);
    }
  },
};
</script>

<style lang="scss" scoped>
.content-card {
  margin-top: 15px;
  padding: 35px 18px;
  background: #F1F1F1;
  border-radius: 20px
}
</style>
