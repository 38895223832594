import { render, staticRenderFns } from "./OrderTotalizers.vue?vue&type=template&id=74bd1302&scoped=true&"
import script from "./OrderTotalizers.vue?vue&type=script&lang=js&"
export * from "./OrderTotalizers.vue?vue&type=script&lang=js&"
import style0 from "./OrderTotalizers.vue?vue&type=style&index=0&id=74bd1302&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74bd1302",
  null
  
)

export default component.exports