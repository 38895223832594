<template>
  <div>
    <PageHeader :schema="this.$schemas.account" :titleCustom="pageHeader.title"
      :descriptionCustom="pageHeader.description" />
    <PageContent>
      <TitleInformation ref="TitleInformation" :model="account"
        v-bind="propsTitleInformation" @generateInstallments="generateInstallments" />
      <Installments ref="Installments" class="mt-5" :model="account" @findAccount="find" />
      <Totalizers ref="Totalizers" class="mt-5" :model="account" />
      <Actions ref="Actions" class="mt-5" :model="account" :loading="loadingSave"
        @save="save"  />
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import TitleInformation from '../components/TitleInformation';
import Installments from '../components/Installments.vue';
import Totalizers from '../components/Totalizers';
import Actions from '../components/Actions';
import { typePage } from '@/utils/enums';
import TypePageMixin  from '@/mixins/TypePageMixin';
import { accountTypes, statusAccountParcels } from '@/utils/enums';
import { messageErrors, formatCurrency } from '@/utils';
import Account from '../model/Account';
import { kebabCase, clone } from 'lodash';

export default {
  name: 'CreateAccount',
  components: {
    PageHeader,
    PageContent,
    TitleInformation,
    Installments,
    Totalizers,
    Actions,
  },
  data () {
    return {
      account: Account.model,
      propsTitleInformation: {
        registers: [],
        type_installments: [],
        accounting_accounts: [],
        cost_centers: [],
      },
      loadingSave: false,
      payment: {
        total_products: 0,
        total_discount_value: 0,
        total_order: 0,
      },
      installmentSelected: null,
    };
  },
  computed: {
    l () {
      return this.$locales.pt.accounts.create;
    },
    routerListName () {
      if (kebabCase(this.$route.meta.type) === accountTypes.ACCOUNT_RECEIVABLE) {
        return this.$schemas.account.routes.accountReceivable.list.name;
      }

      return this.$schemas.account.routes.accountPayment.list.name;
    },
    pageHeader () {
      if (kebabCase(this.$route.meta.type) === accountTypes.ACCOUNT_RECEIVABLE) {
        return {
          title: 'Conta a Receber',
          description: 'Crie uma Nova Conta a Receber',
        };
      }

      return {
        title: 'Conta a Pagar',
        description: 'Crie uma Nova Conta a Pagar',
      };
    }
  },
  mixins: [TypePageMixin],
  methods: {
    async mountSelectItems () {
      this.$api.accounts.getSelectItems().then((res) => {
        this.propsTitleInformation = res;
      });
    },
    async generateInstallments () {
      if (!this.account.type_installment?.value || !this.account?.emission_date || !this.account?.value) {
        return;
      }
      let total_value = this.account.total_value - this.account.paid_value_parcels;

      const partialParcels = this.account.parcels.filter((parcel) => {
        if (parcel.payments) {
          const paidValue = parcel.payments.reduce((acc, item) => {
            acc += item.value;

            return acc;
          }, 0);

          return parcel.payments.length > 0 && paidValue < parcel.total_value;
        }

        return false;
      }).map((parcel) => {
        const paidValue = parcel.payments.reduce((acc, item) => {
          acc += item.value;

          return acc;
        }, 0);

        return {
          ...parcel,
          status: statusAccountParcels.PAID,
          value: paidValue,
          value_formatted: formatCurrency(paidValue),
          difference: 0,
          difference_formatted: formatCurrency(0),
          total_value: paidValue,
          total_value_formatted: formatCurrency(paidValue),
          total_paid_value: 0,
          total_paid_value_formatted: formatCurrency(0),
          edit_values: true
        };
      });

      const payload = {
        type_installment_id: this.account.type_installment.value,
        emission_date: this.account.emission_date,
        total_value,
        initial_parcel: 1 + this.account.qtd_parcels_paid + partialParcels.length,
      };

      if (this.typePage === typePage.SHOW) {
        this.account.installment = true;
      }

      let parcels_paid = [];

      if (this.account.qtd_parcels_paid > 0) {
        parcels_paid = this.account.parcels.filter(parcel => parcel.status === statusAccountParcels.PAID);
      }

      this.$api.accounts.generateInstallmentsV2(payload).then((res) => {
        this.account.parcels = res.map(item => item);
        this.account.parcels.unshift(...partialParcels);
        this.account.parcels.unshift(...parcels_paid);
        this.account.recalculate_parcels = true;
      }).catch((err) => {
        this.$noty.error(messageErrors(err.response));
      });
    },
    async save() {
      if (!this.$refs.TitleInformation.$refs.validFormInformation.validate()) {
        return false;
      }
      this.loadingSave = true;

      if (kebabCase(this.$route.meta.type) === accountTypes.ACCOUNT_RECEIVABLE) {
        this.account = {
          ...this.account,
          type: accountTypes.ACCOUNT_RECEIVABLE
        };
      }

      if (this.typePage === typePage.CREATE) {
        this.$api.accounts.create(this.account).then(() => {
          this.$noty.success('Título criado com sucesso!');
          this.$router.push({ name: this.routerListName });
        }).catch(() => {
          this.$noty.error('Erro ao criar o Título');
        }).finally(() => {
          this.loadingSave = false;
        });
      } else {
        const { id } = this.$route.params;
        this.$api.accounts.update(id, this.account).then(() => {
          this.$noty.success('Título atualizado com sucesso!');
          this.find();
        }).catch(() => {
          this.$noty.error('Erro ao atualizar o Título');
        }).finally(() => {
          this.loadingSave = false;
        });
      }
    },
    async find () {
      const { id } = this.$route.params;
      this.$api.accounts.show(id).then((res) => {
        this.account = clone(res.account);

        if (!this.account.register_id) {
          this.propsTitleInformation.registers.unshift({ value: null, text: 'Não Informado'});
        }
      });
    },
  },
  mounted () {
    this.mountSelectItems();
    if (this.typePage === typePage.SHOW) this.find();
  },
};
</script>
