import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import OrderSchema from '../schemas/OrderSchema';
import { formatDatePT, formatCurrency, formatPhone, getText } from '@/utils';
import { typePage } from '@/utils/enums';
import { clone } from 'lodash';
import { productInvoicesStatus } from '@/utils/options';

const transformArrayToListSelect = (arr, params = ['name', 'id']) => {
  return arr.map((item) => {
    return {
      text: item[params[0]],
      value: item[params[1]],
      ...item.value && { item_value: item.value },
      ...params.includes('all') && { item },
    };
  });
};

const formatResponse = (item, type = typePage.LIST) => {
  const formatList = (item) => {
    item.emission_date_formatted = formatDatePT(item.emission_date);
    item.created_at_formatted = formatDatePT(item.created_at);
    item.delivery_date_formatted = formatDatePT(item.delivery_date);
    item.total_products_formatted = formatCurrency(item.total_products);
    item.total_discount_value_formatted = formatCurrency(item.total_discount_value);
    item.total_taxes_formatted = formatCurrency(item.total_taxes);
    item.total_freight_formatted = formatCurrency(item.total_freight);
    item.total_expense_formatted = formatCurrency(item.total_expense);
    item.sub_total_formatted = formatCurrency(item.total_products);
    item.discount_formatted = formatCurrency(item.total_discount_value);
    item.total_value_replacement_formatted = formatCurrency(item.total_value_replacement);
    item.total_order_formatted = formatCurrency(item.total_order);

    if (item.client) {
      item.client = {
        ...item.client,
        address: {
          ...item.client.address,
          city_formatted: `${item.client.address.city}/${item.client.address.uf}`
        }
      };
    }

    if (item.product_invoice) {
      item.product_invoice.status_formatted = getText(productInvoicesStatus, item.product_invoice.status);
    }

    if (item.client_id) {
      item.client.phone_formatted = formatPhone(item.client?.phone);
      item.client.cell_phone_formatted = formatPhone(item.client?.cell_phone);
    } else {
      item.client = {
        name: 'Não informado'
      };
    }
  };

  const formatShow = (order) => {
    const mountSelect = (key, data, no_id = false) => {
      if (!data) return null;

      return {
        ...!no_id && { [`${key}_id`]: data?.id },
        [key]: {
          text: data?.name,
          value: data?.id
        }
      };
    };

    item.order = {
      ...order,
      ...mountSelect('client', order.client),
      ...mountSelect('seller', order.seller),
      payment_method: { ...mountSelect('payment_method', order.payment_method)?.payment_method, code: order.payment_method?.value },
      ...mountSelect('vehicle', order.vehicle),
      ...mountSelect('type_installment', order.type_installment),
      ...mountSelect('stock', order.stock),
      ...mountSelect('price_list', order.price_list),
      ...mountSelect('nature_operation', order.nature_operation),
      transporter: {
        ...order.transporter,
        plate: order.transporter?.vehicle?.plate,
        uf: order.transporter?.vehicle?.uf,
        rntc: order.transporter?.vehicle?.rntc,
      },
      devolution_histories: order.devolution_histories.map((item) => {
        return {
          ...item,
          devolution_date_formatted: formatDatePT(item.devolution_date),
        };
      }),
      payments: order.payments.map((payment) => {
        return {
          ...payment,
          created_at_formatted: formatDatePT(payment.created_at),
          value_formatted: formatCurrency(payment.value)
        };
      }),
    };

    item.order.items = item.order.items.map((item) => {
      return {
        ...item,
        stock_id: item.product_variation_stock?.stock_id,
        tax: {
          ...item.tax,
          approximates_value: {
            base: 0,
            aliquot: 0,
            value: 0,
          }
        }
      };
    });

    item.order.items_replacement = item.order.items_replacement.map((item) => {
      return {
        ...item,
        quantity_formatted: formatCurrency(item.quantity, { type: 'decimal' }),
        unit_value_formatted: formatCurrency(item.unit_value),
        sub_total_formatted: formatCurrency(item.sub_total),
        discount_value_formatted: formatCurrency(item.discount_value),
        total_value_formatted: formatCurrency(item.total_value),
        stock_id: item.product_variation_stock?.stock_id,
        is_replacement: true,
      };
    });
  };

  type === typePage.LIST ? formatList(item) : formatShow(item.order);
};

const formatResponseGetSelectItems = (res) => {
  res.sellers = transformArrayToListSelect(res.sellers);
  res.transporters = transformArrayToListSelect(res.transporters);
  res.payment_methods = transformArrayToListSelect(res.payment_methods);
  res.stocks = transformArrayToListSelect(res.stocks);
  res.type_installments = transformArrayToListSelect(res.type_installments);
  res.price_lists = transformArrayToListSelect(res.price_lists);
  res.nature_operations = transformArrayToListSelect(res.nature_operations, ['name', 'id', 'all']);
  res.ncms = transformArrayToListSelect(res.ncms);
  res.cests = transformArrayToListSelect(res.cests);
  res.unit_measures = transformArrayToListSelect(res.unit_measures);
  res.vehicles = transformArrayToListSelect(res.vehicles, ['plate', 'id', 'all']);

  return res;
};

const formatRequest = (data, type = typePage.CREATE) => {
  const payload = clone(data);

  const removeKey = (obj, keys) => {
    keys.forEach((key) => {
      delete obj[key];
    });
  };

  removeKey(payload, ['id', 'company_id', 'status',
    'payment_method', 'type_installment', 'price_list', 'nature_operation',
    'client', 'seller', 'stock', ]);

  payload.items.forEach((item) => {
    removeKey(item, ['product', 'product_variation',
      'product_variation_stock']);
  });

  payload.items_replacement.forEach((item) => {
    removeKey(item, ['product', 'tax']);
  });

  const formatUpdate = (payload) => {
    removeKey(payload.transporter, ['order_id', 'register', 'vehicle']);

    payload.deleted_items = payload.deleted_items.map((item) => {
      return { id: item.id };
    });

    if (!payload.transporter.register_id) {
      delete payload.transporter;
    }
  };

  if (type === typePage.SHOW) formatUpdate(payload);

  return payload;
};

const formatResponseCalculateItemTaxes = (res) => {
  return {
    ...res,
    approximates_value: {
      base: 0,
      aliquot: 0,
      value: 0,
    }
  };
};

const formatResponseGetOrderSales = (res) => {
  return res.orders.map((item) => {
    return {
      ...item,
      items: item.items.map((item) => {
        return {
          ...item,
          tax: {
            ...item.tax,
            approximates_value: {
              base: 0,
              aliquot: 0,
              value: 0,
            }
          }
        };
      }),
      text_select: `Pedido Nº: ${item.order_number} - Cliente: ${item.client.name} - Data: ${formatDatePT(item.emission_date)}`,
      qtd_devolution: 0,
    };
  });
};

const OrdersService = DynamicService(OrderSchema.domain, OrderSchema, {
  formatResponse, formatRequest, removeNullable: false
});

const OrdersCommands = () => ({
  async getSelectItems() {
    const { data } = await axios.post(`${OrderSchema.domain}/get_list_selects`);

    return formatResponseGetSelectItems(data);
  },
  async calculateItemTaxes(payload) {
    const { data } = await axios.post('calculate-item-taxes', payload);

    return formatResponseCalculateItemTaxes(data);
  },
  async generateInvoice(payload) {
    const { data } = await axios.post(`${OrderSchema.domain}/generate-invoice`, payload);

    return data;
  },
  async createPdv(payload) {
    return await axios.post(`${OrderSchema.domain}/pdv`, payload);
  },
  async getOrderSales(type, registerId) {
    const { data } = await axios.get(`filters?domain=order&filter[type]=${type}&filter[client_id]=${registerId}&page=1&perPage=10`);

    return formatResponseGetOrderSales(data);
  },
  async getOrderItemToDevolution(search) {
    const { data } = await axios.post(`${OrderSchema.domain}/get_order_item_to_devolution`, search);

    return data;
  },
  async generateDevolutionOrder(payload) {
    const { data } = await axios.post(`${OrderSchema.domain}/generate-order-devolution`, payload);

    return data;
  },
  async reverseOrder(orderId) {
    const { data } = await axios.post(`${OrderSchema.domain}/${orderId}/reverse-order`);

    return data;
  },
});

export default OrdersService;
export { OrdersService, OrdersCommands };
