<script>
import { personType } from '@/utils/enums';
import { hour, cell_phone, zipCode, cpf, cnpj } from '@/utils/masks';
import { required, biggerZero } from '@/utils/rules';

export default {
  name: 'CreateUpdateProductInvoiceMixin',
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      masks: {
        hour,
        cell_phone,
        zipCode,
        cpf,
        cnpj,
      },
      rules: { required, biggerZero }
    };
  },
  computed: {
    isAuthorized () {
      return this.model.status === this.$enums.productInvoiceStatus.AUTHORIZED;
    }
  },
  methods: {
    getValue (value) {
      return value || '&nbsp;';
    },
    getMaskDocument (type, typePerson) {
      let mask = '';
      typePerson === personType.PHYSICAL ? mask = this.masks.cpf : mask = this.masks.cnpj;

      if (type === 'input') {
        return mask;
      } else {
        const regex = /[^#]/g;

        return mask.replace(regex, '');
      }
    },
    calculateTotals () {
      let icmsBaseCalculate = 0;
      let icmsValue = 0;
      let icmsStBaseCalculate = 0;
      let icmsStValue = 0;
      let ipiValue = 0;
      let taxApproximatesValue = 0;
      let productsValue = 0;
      let discountValue = 0;
      let totalWeightLiquid = 0;
      let totalWeightGross = 0;

      this.model.items.forEach((item) => {
        icmsBaseCalculate += parseFloat(item.tax.icms.base);
        icmsValue += parseFloat(item.tax.icms.value);
        icmsStBaseCalculate += parseFloat(item.tax.icms_st.base);
        icmsStValue += parseFloat(item.tax.icms_st.value);
        ipiValue += parseFloat(item.tax.ipi.value);
        taxApproximatesValue += parseFloat(item.tax.approximates_value.value);
        productsValue += parseFloat(item.sub_total);
        totalWeightLiquid += parseInt(item.total_weight_liquid || 0);
        totalWeightGross += parseInt(item.total_weight_gross || 0);
      });

      this.model.bill.original_value = (parseFloat(productsValue) +
        parseFloat(ipiValue) + parseFloat(icmsStValue) +
        parseFloat(this.model.transport.other_expenses) +
        parseFloat(this.model.transport.safe_value));

      if (this.model.transport.freight_include) {
        this.model.bill.original_value = parseFloat(this.model.bill.original_value) +
          parseFloat(this.model.transport.freight_value);
      }

      discountValue = parseFloat(this.model.total.discount_value);
      this.model.liquid_weight = totalWeightLiquid;
      this.model.gross_weight = totalWeightGross;
      this.model.transport.quantity = this.model.items.length;
      this.model.transport.liquid_weight = totalWeightLiquid;
      this.model.transport.gross_weight = totalWeightGross;
      this.model.total.icms_base_calculate = icmsBaseCalculate;
      this.model.total.icms_value = icmsValue;
      this.model.total.icms_st_base_calculate = icmsStBaseCalculate;
      this.model.total.icms_st_value = icmsStValue;
      this.model.total.ipi_value = ipiValue;
      this.model.total.tax_approximates_value = taxApproximatesValue;
      this.model.total.products_value = productsValue;
      this.model.total.discount_value = discountValue;
    },
  }
};
</script>
