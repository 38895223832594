<template>
  <div>
    <PageHeader :schema="$schemas.order" />
    <PageContent>
      <OrderDetails ref="orderDetails" :model="order" :company-setting="companySetting" />
      <OrderFreight class="mt-5" ref="orderFreight" :model="order" />
      <OrderItems class="mt-5" ref="orderItems" :model="order" />
      <OrderDevolutions v-if="showDevolutions" class="mt-5" ref="orderDevolutions" :model="order" />
      <OrderPayments v-if="showPayments" class="mt-5" ref="orderPayments" :model="order" />
      <OrderDiscount class="mt-5" ref="orderDiscount" :model="order" />
      <OrderTransporter class="mt-5" ref="orderTransporter" :model="order" />
      <OrderTotalizers class="mt-5" ref="orderTotalizers" :model="order" />

      <Row>
        <Col md="12">
          <CheckBox v-model="order.generate_financial"
            @setCheckBox="setGenerateFinancial" :label="'Gerar Financeiro'"
            style="margin: 4px 0 -10px 0" />
        </Col>
      </Row>
      <Row class="mt-6">
        <Col md="2">
          <Button label="Salvar" rounded color="primary" @click="setPayments()"
            :loading="loading" :disabled="disabledButtonSave" />
        </Col>
      </Row>

      <Dialog :dialog="dialogModalPayments" :maxWidth="1200">
        <Payments slot="content" :model="order" @confirmDialog="save"
          @update:dialogModal="dialogModalPayments = $event"
          :loading="loadingPayments" key="keyModalPayments" />
      </Dialog>

      <DialogConfirmation :dialog="dialogConfirmation"
        title="Atualizar dados da Empresa."
        message="Para continuar é necessário atualizar os dados da empresa."
        textYesBtn="Atualizar"
        textNoBtn="Cancelar"
        @noActionDialogConfirmation="noActionDialogConfirmation"
        @yesActionDialogConfirmation="yesActionDialogConfirmation" />
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import { Row, Col, CheckBox, Button, Dialog } from '@/components/vuetify';
import Order from '../model/Order';
import OrderDetails from '../components/OrderDetails';
import OrderFreight from '../components/OrderFreight';
import OrderItems from '../components/OrderItems';
import OrderTransporter from '../components/OrderTransporter';
import OrderDevolutions from '../components/OrderDevolutions';
import OrderPayments from '../components/OrderPayments';
import OrderTotalizers from '../components/OrderTotalizers';
import OrderDiscount from '../components/OrderDiscount';
import DialogConfirmation from '@/components/DialogConfirmation';
import { presenceIndicator, freights } from '@/utils/options';
import { typePage, orderTypes } from '@/utils/enums';
import { getCompanyIdLogged, randomNumber, getCurrentPeriod } from '@/utils';
import TypePageMixin  from '@/mixins/TypePageMixin';
import { kebabCase, clone } from 'lodash';
import Payments from '@/components/OrderProductInvoice/Payments';
import GetCompanySettingsMixin from '@/mixins/GetCompanySettingsMixin';
import OrderNumberMixin from '../mixins/OrderNumberMixin';

export default {
  name: 'CreateOrder',
  components: {
    PageHeader,
    PageContent,
    Row,
    Col,
    CheckBox,
    Button,
    OrderDetails,
    OrderFreight,
    OrderItems,
    OrderTransporter,
    OrderDevolutions,
    OrderDiscount,
    OrderTotalizers,
    Payments,
    Dialog,
    DialogConfirmation,
    OrderPayments
  },
  data () {
    return {
      order: Order.model,
      clients: [],
      sellers: [],
      transporters: [],
      vehicles: [],
      paymentMethods: [],
      typeInstallments: [],
      presenceIndicator: [],
      stocks: [],
      priceLists: [],
      natureOperations: [],
      freights: [],
      products: [],
      ncms: [],
      cests: [],
      unit_measures: [],
      loading: false,
      loadingSelects: false,
      dialogModalPayments: false,
      dialogConfirmation: false,
      loadingPayments: false,
      keyModalPayments: 0
    };
  },
  async mounted() {
    const companyId = getCompanyIdLogged();
    this.$api.companies.show(companyId).then((res) => {
      const { company } = res;

      if (!company.address) {
        this.dialogConfirmation = true;
      }
    }).catch((err) => {
      this.$noty.error(err);
    });
    await this.mountSelectItems();
    this.order.type = kebabCase(this.$route.meta.type);
    if ((this.typePage === typePage.SHOW || this.$route.query.duplicateOrder) && this.$route.params.id) this.find();

    if (this.$route.query.duplicateOrder && !this.$route.params.id) {
      this.$router.push({ name: this.$schemas.order.routes[this.$route.meta.type].list.name });
    }
  },
  provide () {
    const propsComponents = {};
    const keys = ['sellers', 'transporters', 'vehicles', 'paymentMethods',
      'presenceIndicator', 'typeInstallments', 'priceLists', 'products',
      'natureOperations', 'freights', 'stocks', 'ncms', 'cests', 'unit_measures',
      'loadingSelects'];

    keys.forEach((key) => {
      Object.defineProperty(propsComponents, key, {
        enumerable: true,
        get: () => this[key]
      });
    });

    return {
      propsComponents,
    };
  },
  computed: {
    showDevolutions () {
      return this.order.devolution_histories.length > 0 || false;
    },
    showPayments () {
      return this.order.payments.length > 0 || false;
    },
    disabledButtonSave() {
      return this.order.is_delivered;
    },
  },
  mixins:  [TypePageMixin, GetCompanySettingsMixin, OrderNumberMixin],
  methods: {
    async mountSelectItems () {
      this.loadingSelects = true;
      const res = await this.$api.orders.getSelectItems();
      this.sellers = res.sellers;
      this.transporters = res.transporters;
      this.paymentMethods = res.payment_methods;
      this.presenceIndicator = presenceIndicator;
      this.stocks = res.stocks;
      this.typeInstallments = res.type_installments;
      this.priceLists = res.price_lists;
      this.natureOperations = res.nature_operations;
      this.freights = freights;
      this.products = res.products;
      this.ncms = res.ncms;
      this.cests = res.cests;
      this.unit_measures = res.unit_measures;
      this.vehicles = res.vehicles;
      this.loadingSelects = false;

      //ESPERA O MOUNT DOS SELECTS PARA PEGAR AS CONFIGURAÇÕES DA EMPRESA
      this.order.is_custom_items = this.companySetting[this.order.type === orderTypes.ORDER_SALE_DEVOLUTION ? 'order_sale_devolution_is_custom_items' : 'order_purchase_devolution_is_custom_items'];
    },
    async find () {
      const { id } = this.$route.params;

      this.$api.orders.show(id).then((res) => {
        this.order = res.order;
        this.order.client_id = res.order.client?.value || '';
        this.order.seller_id = res.order.seller?.value || '';
        this.order.generate_financial = true;
        this.order.deleted_items = [];
        this.order.deleted_items_replacement = [];
        this.order.include_discount_value_total = true;
        this.order.include_discount_value_total_replacement = true;
        this.order.total_discount_general = this.order.items.filter(item => !item.is_individual_discount).reduce((acc, item) => acc + parseFloat(item.discount_value), 0);

        if (this.$route.query.duplicateOrder) {
          this.changeDuplicateOrder();
        }
      }).catch((err) => {
        this.$noty.error(err);
      });
    },
    save (data) {
      const { data: { payments } } = data;
      this.loading = true;
      this.loadingPayments = true;

      const routeListName = this.$schemas.order.routes[this.$route.meta.type].list.name;
      this.order.type = kebabCase(this.$route.meta.type);
      let payload = {
        ...clone(this.order),
        payments
      };

      if (this.typePage === typePage.CREATE)  {
        this.$api.orders.create(payload).then(({ order }) => {
          this.$noty.success('Pedido criado com sucesso!');
          this.$router.push({ name: routeListName, params: { generateInvoice: payload.delivery_date || false, order } });
        }).catch((err) => {
          this.$noty.error(err);
        }).finally(() => {
          this.loading = false;
          this.loadingPayments = false;
        });
      } else {
        const id = this.$route.params.id;

        this.$api.orders.update(id, payload).then(() => {
          this.$noty.success('Pedido atualizado com sucesso!');
          this.$router.push({ name: routeListName, params: { generateInvoice: payload.delivery_date || false, order: this.order } });
        }).catch((err) => {
          this.$noty.error(err);
        }).finally(() => {
          this.loading = false;
          this.loadingPayments = false;
        });
      }
    },
    setGenerateFinancial (value) {
      this.order.generate_financial = value || false;
    },
    setPayments () {
      if (this.order.type_installment_id) {
        this.save({ action: 'confirmFinishSale', data: { payments: [] } });

        return;
      }

      if (!this.$refs.orderDetails.$refs.validFormDetails.validate() ||
        !this.$refs.orderFreight.$refs.validFormFreight.validate()) {
        this.$noty.error('Preencha os dados Obrigatórios');

        return false;
      }

      if (!this.$refs.orderDetails.$refs.validFormDetails.validate() ||
        !this.$refs.orderFreight.$refs.validFormFreight.validate()) {
        return false;
      }

      if ((this.order.freight === this.$enums.freights.ISSUER ||
        this.order.freight === this.$enums.freights.RECIPIENT) && !this.order.transporter.register_id) {
        this.$noty.error('Os dados da Trasportadora são Obrigatórios');

        return false;
      }

      if (this.order.items.length === 0) {
        this.$noty.error('É necessário adicionar pelo menos um item no pedido!');

        return false;
      }

      if (this.order.delivery_date) {
        this.keyModalPayments = randomNumber();
        this.dialogModalPayments = true;
      } else {
        this.save({ data: { payments: [] } });
      }
    },
    noActionDialogConfirmation () {
      this.$router.push({ name: this.$schemas.order.routes[this.$route.meta.type].list.name });
    },
    yesActionDialogConfirmation () {
      const companyId = getCompanyIdLogged();
      this.$router.push({ name: this.$schemas.company.routes.show.name, params: { id: companyId } });
    },
    changeDuplicateOrder() {
      this.order = {
        ...this.order,
        items: this.order.items.map((item) => {
          return {
            ...item,
            discount_percentage: 0,
            discount_value: 0,
            total_value: item.sub_total,
          };
        }),
        id: null,
        order_number: this.setOrderNumber(),
        invoice_number: null,
        delivery_date: null,
        is_delivered: false,
        emission_date: getCurrentPeriod().currentDateEN,
        status: this.$enums.statusOrders.PENDING,
        items_replacement: [],
        total_value_replacement: 0,
        total_discount_value: 0,
        total_discount_percentage: 0,
        total_discount_general: 0,
        total_order: this.order.total_products,
      };

      if (!this.$route.query.duplicateItems) {
        this.order.items = [];
      }
    }
  },
};
</script>
