<template>
  <div>
    <DataTable class="mt-5" no-search no-sync :items.sync="items"
      :headers="headers" :slots="slotsItems" show-expand>
      <div slot="custom-header" class="d-flex justify-end mb-10">
        <Row class="d-flex justify-start">
          <Col md="3">
              <input v-model="search"  class="search-input" type="text"
                id="search" ref="search" placeholder="Pesquisar"
                @keyup.enter="handleSearch"  />
          </Col>
          <Col md="9" class="d-flex justify-end">
            <Button :label="l.actions.newItem.label" small rounded color="secondary"
              @click="openDialogProduct" v-if="!disabledNewItemButton" />
          </Col>
        </Row>
      </div>
      <template v-slot:quantity="{ props: { item } }">
        {{ formatCurrency(item.quantity, { type: 'decimal' }) }}
      </template>
      <template v-slot:unit_value="{ props: { item } }">
        {{ formatCurrency(item.unit_value) }}
      </template>
      <template v-slot:sub_total="{ props: { item } }">
        {{ formatCurrency(item.sub_total) }}
      </template>
      <template v-slot:discount_value="{ props: { item } }">
        {{ formatCurrency(item.discount_value) }}
      </template>
      <template v-slot:total_value="{ props: { item } }">
        {{ formatCurrency(item.total_value) }}
      </template>
      <template v-if="!noDestroy" v-slot:actions="{ props }">
        <div class="d-flex">
          <ButtonIcon v-if="showEdit(props.item)" color="secondary" x-small :icon="$icons.edit"
            @click="openDialogEdit(props)" :tooltip="l.tooltips.editItem"
             />
          <ButtonIcon color="primary" x-small :icon="$icons.destroy"
            @click="openDialogConfirmation(props)" :tooltip="l.tooltips.removeItem" />
        </div>
      </template>

      <template v-slot:content-expanded="{ props: { item } }">
        <Row class="px-5 py-5">
          <Col class="">
            <span v-text="`Referência:`" class="d-block font-weight-bold subtitle-1" />
            <span v-text="item.reference" />
          </Col>
          <Col class="">
            <span v-text="`Cod. Barras:`" class="d-block font-weight-bold subtitle-1" />
            <span v-text="item.bar_code" />
          </Col>
          <Col class="">
            <span v-text="`Uni. Medida:`" class="d-block font-weight-bold subtitle-1" />
            <span v-text="item.unit_measure" />
          </Col>
          <Col class="">
            <span v-text="`Desconto:`" class="d-block font-weight-bold subtitle-1" />
            <span v-text="`${formatCurrency(item.discount_percentage, { type: 'decimal' })}%`" />
          </Col>
          <Col class="">
            <span v-text="`NCM:`" class="d-block font-weight-bold subtitle-1" />
            <span v-text="item.ncm" />
          </Col>
          <Col class="">
            <span v-text="`CEST:`" class="d-block font-weight-bold subtitle-1" />
            <span v-text="item.cest" />
          </Col>
        </Row>
        <Row class="px-5 pb-5">
          <Col>
            <span v-text="`Observações:`" class="d-block font-weight-bold subtitle-1" />
            <span v-text="item.observation" />
          </Col>
        </Row>
      </template>

      <template v-slot:custom-footer>
        <Row>
          <Col col="6" md="2">
            <span class="font-weight-bold subtitle-1">Quant. Itens: </span>
            <span class="font-weight-bold subtitle-1">{{ quantityItems }}</span>
          </Col>
          <Col>
            <span class="font-weight-bold subtitle-1">Quant. Volumes: </span>
            <span class="font-weight-bold subtitle-1">{{ quantityVolumes }}</span>
          </Col>
        </Row>
      </template>
    </DataTable>

    <Dialog :dialog="dialogModal" :max-width="propsComponent.maxWidth">
      <component
        :is="componentModal" slot="content"
        v-bind="propsComponent"
        v-on="eventsComponent" />
    </Dialog>
    <DialogConfirmation :dialog="dialogConfirmation"
      @yesActionDialogConfirmation="removeItem"
      @noActionDialogConfirmation="closeDialogConfirmation"
      message="Os totais serão recalculados." />
  </div>
</template>

<script>
import {
  Dialog,
  DataTable,
  Button,
  ButtonIcon,
  Row,
  Col,
} from '@/components/vuetify';
import ItemsDataListItemsProduct from './ItemsDataListItemsProduct';
import ItemsDataListItemsProductOrderDevolution from './ItemsDataListItemsProductOrderDevolution';
import DialogConfirmation from '@/components/DialogConfirmation';
import { formatCurrency , checkUuid, randomNumber } from '@/utils';
import IsDevolutionMixin from '@/views/Movements/Orders/mixins/IsDevolutionMixin';
import InputFocusMixin from '@/mixins/InputFocusMixin';
import { formatProductVariations } from '@/utils';
import ItemsDataListItemsEditModal from './ItemsDataListItemsEditModal';

export default {
  name: 'ItemsDataListItems',
  components: {
    DataTable,
    Button,
    ButtonIcon,
    Dialog,
    ItemsDataListItemsProduct,
    ItemsDataListItemsProductOrderDevolution,
    DialogConfirmation,
    Row,
    Col,
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    priceListId: {
      type: String,
      default: null
    },
    clientId: {
      type: String,
      required: true
    },
    natureOperationId: {
      type: String,
      required: true
    },
    stockId: {
      type: String,
      default: ''
    },
    stockRequired: {
      type: Boolean,
      default: true
    },
    noDestroy: {
      type: Boolean,
      default: true
    },
    disabledNewItemButton: {
      type: Boolean,
      default: false
    },
    isWholesale: {
      type: Boolean,
      default: false
    },
    isCustomItems: {
      type: Boolean,
      default: false
    },
    isReplacement: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      itemSelected: {},
      dialogModal: false,
      dialogConfirmation: false,
      componentModal: ItemsDataListItemsProduct,
      propsComponent: {},
      eventsComponent: {},
      formatCurrency: formatCurrency,
      slotsItems: [
        { field: 'quantity' },
        { field: 'unit_value' },
        { field: 'discount_percentage' },
        { field: 'sub_total' },
        { field: 'discount_value' },
        { field: 'total_value' },
      ],
      search: '', //7890000000027
      searchProduct: null,
    };
  },
  computed: {
    l () {
      return this.$locales.pt.orders.create.items.dataList;
    },
    schema () {
      return this.$schemas.order.create.items;
    },
    headers () {
      return this.schema.headers.filter((item) => item.value !== 'actions' || !this.noDestroy);
    },
    quantityItems () {
      return this.items.length;
    },
    quantityVolumes () {
      return this.items.reduce((acc, item) => parseFloat(acc) + parseFloat(item.quantity), 0);
    },
  },
  mixins: [IsDevolutionMixin, InputFocusMixin],
  methods: {
    openDialogProduct () {
      let error = false;

      if (!checkUuid(this.natureOperationId)) {
        this.$noty.error('Selecione a Natureza de Operação');
        error = true;
      }

      if (!checkUuid(this.clientId)) {
        this.$noty.error('Selecione o Cliente');
        error = true;
      }

      if (this.stockRequired && !checkUuid(this.stockId)) {
        this.$noty.error('Selecione o Estoque');
        error = true;
      }

      if (error) {
        return;
      }

      this.dialogModal = true;

      if (this.isDevolution && !this.isCustomItems) {
        this.propsComponent = {
          maxWidth: 1200,
          key: randomNumber(),
          registerId: this.clientId,
          natureOperationId: this.natureOperationId,
        };
        this.eventsComponent = {
          addItem: this.addItem,
          close: this.closeDialog,
        };
        this.componentModal = ItemsDataListItemsProductOrderDevolution;
      } else {
        this.componentModal = ItemsDataListItemsProduct,

        this.propsComponent = {
          maxWidth: 1200,
          priceListId: this.priceListId,
          clientId: this.clientId,
          natureOperationId: this.natureOperationId,
          stockId: this.stockId,
          qtdItems: this.items.length,
          searchProduct: this.searchProduct,
          key: randomNumber(),
          isWholesale: this.isWholesale,
          isReplacement: this.isReplacement,
        };
        this.eventsComponent = {
          addItem: this.addItem,
          close: this.closeDialog,
        };
      }
    },
    openDialogEdit(item) {
      this.componentModal = ItemsDataListItemsEditModal,
      this.dialogModal = true;
      this.itemSelected = item;
      this.propsComponent = {
        item: item.item,
        clientId: this.clientId,
        key: randomNumber(),
        maxWidth: 800,
      };
      this.eventsComponent = {
        editItem: this.editItem,
        close: this.closeDialog,
      };
    },
    addItem (data) {
      this.dialogModal = false;
      this.$emit('addItem', data);
    },
    editItem(data) {
      this.dialogModal = false;
      this.$emit('editItem', { index: this.itemSelected.index, data});
    },
    removeItem () {
      this.closeDialogConfirmation();
      this.$emit('removeItem', {
        index: this.itemSelected.index,
        item: this.itemSelected.item,
      });
    },
    openDialogConfirmation (item) {
      this.itemSelected = item;
      this.dialogConfirmation = true;
    },
    closeDialogConfirmation () {
      this.dialogConfirmation = false;
    },
    handleSearch() {
      const reset = () => {
        this.search = '';
        this.searchProduct = null;
        this.focusInputs(this.$refs[['search']]);
      };

      if (!this.search) {
        this.$noty.error('Informe o código de barras');
        reset();

        return;
      }

      this.$api.productVariations
        .filters({ url: `filters?domain=product_variation&filter[bar_code]=${this.search}&page=1&per_page=-1` })
        .then(({ data: { product_variations } }) => {
          if (product_variations.length === 0) {
            this.$noty.error('Produto não localizado');
            reset();

            return;
          }

          if (product_variations.length > 1) {
            this.$noty.error('Código de Barras duplicado');
            reset();

            return;
          }
          this.searchProduct = {
            id: product_variations[0].product.id,
            unit_measure: product_variations[0].product.unit_measure,
            ncm: product_variations[0].product.ncm,
            cest: product_variations[0].product.cest,
            product_variation_id: product_variations[0].id,
            product_variation: formatProductVariations(product_variations)[0]
          };

          this.openDialogProduct();
          reset();
        });
    },
    closeDialog () {
      this.dialogModal = false;
    },
    showEdit (item) {
      return !item.is_replacement;
    },
  },
};
</script>

<style lang="scss">
.action-column-items {
  right: 0px;
  position: sticky;
  padding: 0 3px !important;
  width: 30px !important;
  background: rgba($color: #FFF, $alpha: 0.9);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.search-input {
  background: #FFF;
  padding: 7px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #999;
}
</style>
