<template>
  <ModalCardDefault title="Alterar Item" @close="$emit('close', false)">
    <v-form v-model="validFormProduct" ref="validFormProduct" lazy-validation>
      <ExpansionPanel v-model="panel" title="Dados do Item" :icon="$icons.box"
        readonly>
        <Row>
          <Col>
            <TextFieldPercent v-model="localForm.quantity" placeholder="Quantidade"
              @input="setDiscountAndWeight('value')" :length="1000" label="Quantidade" />
          </Col>
          <Col>
            <TextFieldMoney v-model="localForm.unit_value"
              @input="setDiscountAndWeight('value')" placeholder="Vlr. Unitário" label="Vlr. Unitário" />
          </Col>
          <Col>
            <TextFieldMoney v-model="localForm.sub_total"
              placeholder="Sub Total" label="Sub Total" />
          </Col>
          <Col>
            <TextFieldMoney v-model="localForm.total_value"
              placeholder="Vlr. Total" label="Vlr. Total" />
          </Col>
        </Row>
      </ExpansionPanel>
    </v-form>

    <div slot="actions">
      <Button label="Confirmar"  @click="editItem"
        class="mr-3" rounded color="secondary" :loading="loadingTaxes" />
    </div>
  </ModalCardDefault>
</template>

<script>
import {
  ExpansionPanel,
  Row,
  Col,
  TextFieldMoney,
  TextFieldPercent,
  Button,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import SetDiscountAndWeightMixin from './mixins/SetDiscountAndWeightMixin';
import CalculateItemTaxesMixin from './mixins/CalculateItemTaxesMixin';
import { clone } from 'lodash';

export default {
  name: 'ItemsDataListItemsEditModal',
  components: {
    ExpansionPanel,
    ModalCardDefault,
    Row,
    Col,
    TextFieldMoney,
    TextFieldPercent,
    Button,
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    clientId: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      panel: [0],
      validFormProduct: false,
      loadingTaxes: false,
      localForm: clone({
        ...this.item,
        unit_measure: { item_value: this.item.unit_measure },
        ncm: { item_value: this.item.ncm },
        cest: { item_value: this.item.cest },
      }),
    };
  },
  mixins: [SetDiscountAndWeightMixin, CalculateItemTaxesMixin],
  methods: {
    async editItem () {
      if (this.$refs?.validFormProduct?.validate()) {
        try {
          this.loadingTaxes = true;
          this.localForm = await this.calculateItemTaxes(this.localForm, this.clientId);

          this.$emit('editItem', this.localForm);
          this.resetLocalForm();
          this.loadingTaxes = false;
        } catch (err) {
          this.loadingTaxes = false;
          this.$noty.error('Erro ao adicionar o item');
        }

      }
    },
    resetLocalForm () {
      this.localForm = {};
    },
  },
};
</script>

<style lang="scss">

</style>
