<template>
  <ModalCardDefault :title="'Finalizar'" :displayNoButton="false">
    <Row>
      <Col md="8">
        <ExpansionPanel v-model="panel" :title="'Pagamentos'"
          :icon="$icons.box" readonly style="min-height: 350px">
          <v-form v-model="pdvValidForm" ref="pdvValidForm" lazy-validation>
            <Row>
              <Col md="4">
                <Select v-model="paymentSelected" label="Forma Pagamento"
                  :items="paymentMethods" return-object :rules="[rules.required]"
                  @change="changePaymentMethod" />
              </Col>
              <Col md="2">
                <Select v-model="quantityInstallmentsSelected" label="Qtd. Parcelas"
                  :items="quantityInstallments" :readonly="setReadOnly" return-object :rules="[rules.required]" />
              </Col>
              <Col md="4">
                <Select v-model="flagSelected" label="Bandeira" :items="flags"
                 return-object :readonly="setReadOnly" :rules="setFieldsRequired" />
              </Col>
              <Col md="2">
                <TextField ref="textFieldAut" v-model="aut" label="Aut."
                  :readonly="setReadOnly" :rules="setFieldsRequired" />
              </Col>
              <Col md="3">
                <TextFieldMoney v-model="value" label="Valor"
                  :rules="[rules.required]" @change="addPayment" />
              </Col>
              <Col md="3">
                <DataPicker v-model="paidDate" :placeholder="'Data Pagamento'"
                  :label="'Data Pagamento'" :rules="[rules.required]" />
              </Col>
              <Col md="3">
                <TextField v-model="creditLetter.number" label="Carta"
                  :readonly="setReadOnly" v-if="creditLetter.number" />
              </Col>
              <Col md="3">
                <TextField v-model="creditLetter.balance" label="Valor Carta"
                  :readonly="setReadOnly"
                  v-if="creditLetter.number" />
              </Col>
            </Row>
          </v-form>
          <DataTable no-search no-sync hide-default-footer :items="payments"
            :headers="headers" :slots="slots">
            <template v-slot:payment_method="{ props: { item } }">
              {{ item.payment_method.text }}
            </template>
            <template v-slot:installment="{ props: { item } }">
              {{ item.installment.text }}
            </template>
            <template v-slot:flag="{ props: { item } }">
              {{ item.flag.text }}
            </template>
            <template v-slot:aut="{ props: { item } }">
              {{ item.aut }}
            </template>
            <template v-slot:value="{ props: { item } }">
              {{ utils.formatCurrency(item.value) }}
            </template>
            <template v-slot:actions="{ props }">
              <ButtonIcon v-if="!props.item.no_remove" color="primary" x-small :icon="$icons.destroy"
                @click="removePayment(props)" :tooltip="'Remover'" />
            </template>
          </DataTable>
        </ExpansionPanel>
      </Col>
      <Col md="4">
        <ExpansionPanel v-model="panel" :title="'Resumo da Venda'"
          :icon="$icons.box" readonly style="min-height: 350px">
          <Row class="py-0">
            <Col md="7" class="py-0">
              <span class="subtitle-2">SubTotal</span>
            </Col>
            <Col md="5" class="d-flex justify-end py-0">
              <span class="subtitle-2" v-text="subTotal" />
            </Col>
          </Row>
          <Row class="py-0">
            <Col md="7" class="py-0">
              <span class="subtitle-2">Desconto</span>
            </Col>
            <Col md="5" class="d-flex justify-end py-0">
              <span class="subtitle-2" v-text="discount" />
            </Col>
          </Row>
          <Row class="py-0">
            <Col md="7" class="py-0">
              <span class="subtitle-2">Total Final</span>
            </Col>
            <Col md="5" class="d-flex justify-end py-0">
              <span class="subtitle-2" v-text="finalTotal" />
            </Col>
          </Row>
          <Row class="py-1"><v-divider /></Row>
          <Row class="py-0" v-for="(method, index) in paymentMethods" :key="index">
            <Col md="7" class="py-0"><span class="body-2" v-text="method.text" /></Col>
            <Col md="5" class="d-flex justify-end py-0"><span class="body-2" v-text="paymentMethodPaid(method)" /></Col>
          </Row>
          <Row class="py-1"><v-divider /></Row>
          <Row class="py-0">
            <Col md="7" class="py-0">
              <span class="subtitle-1 font-weight-black">Valor Pago</span>
            </Col>
            <Col md="5" class="d-flex justify-end py-0">
              <span class="subtitle-1 font-weight-black"
                v-text="utils.formatCurrency(paidTotal)" />
            </Col>
          </Row>
          <Row class="py-0">
            <Col md="6" class="py-0">
              <span class="subtitle-1 font-weight-black">Falta Pagar</span>
            </Col>
            <Col md="6" class="d-flex justify-end py-0">
              <span class="subtitle-1 font-weight-black"
                v-text="utils.formatCurrency(openedValue)" />
            </Col>
          </Row>
          <Row class="py-0">
            <Col md="7" class="py-0">
              <span class="subtitle-1 font-weight-black">Troco</span>
            </Col>
            <Col md="5" class="d-flex justify-end py-0">
              <span class="subtitle-1 font-weight-black"
                v-text="utils.formatCurrency(changeValue)" />
            </Col>
          </Row>
        </ExpansionPanel>
      </Col>
    </Row>

    <template v-slot:actions>
      <Button color="secondary"
        rounded @click="confirm" :disabled="notCanFinish" class="mr-3" :loading="loading">
        <span class="text-button">Confirmar
          <span :class="['text-button font-weight-black', { 'yellow--text': !notCanFinish }]">(Ctrl + Enter)</span>
        </span>
      </Button>
      <Button color="primary"
        rounded @click="closePayments()" class="mr-3">
        <span class="text-button">Fechar
          <span class="text-button font-weight-black yellow--text">(Esc)</span>
        </span>
      </Button>
    </template>

    <Dialog :dialog="dialogPaymentsCreditLetter" :max-width="1150">
      <PaymentsCreditLetter slot="content" :key="keyPaymentsCreditLetter"
        :register-id="model.client_id" @confirmCreditLetter="confirmCreditLetter"
        @cancelCreditLetter="cancelCreditLetter">
      </PaymentsCreditLetter>
    </Dialog>

    <DialogConfirmation :dialog="dialogConfirmation"
      textYesBtn="Sim (Enter)"
      textNoBtn="Não (Esc)"
      title='Fazer Pagamento'
      message='Finaizar a Venda e Fazer o Pagamento?'
      @yesActionDialogConfirmation="finishSale"
      @noActionDialogConfirmation="dialogConfirmation = false"
      v-bind="propsDialogConfirmation" />
  </ModalCardDefault>
</template>

<script>
import DialogConfirmation from '@/components/DialogConfirmation';
import {
  DataTable,
  Button,
  Row,
  Col,
  ExpansionPanel,
  Select,
  TextField,
  TextFieldMoney,
  ButtonIcon,
  DataPicker,
  Dialog,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { required, biggerZero } from '@/utils/rules';
import { formatCurrency, getCurrentPeriod, randomNumber } from '@/utils';
import PaymentsCreditLetter from './PaymentsCreditLetter';

export default {
  name: 'Payments',
  components: {
    ModalCardDefault,
    DataTable,
    Button,
    Row,
    Col,
    ExpansionPanel,
    Select,
    TextField,
    TextFieldMoney,
    ButtonIcon,
    DialogConfirmation,
    DataPicker,
    PaymentsCreditLetter,
    Dialog,
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    permittedPartialPayment: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      panel: [0],
      slots: [
        { field: 'payment_method' },
        { field: 'installment' },
        { field: 'flag' },
        { field: 'aut' },
        { field: 'value' },
        { field: 'actions' },
      ],
      pdvValidForm: false,
      rules: {
        required,
        biggerZero,
      },
      utils: {
        formatCurrency
      },
      paymentMethods: [],
      installments: [],
      paymentSelected: null,
      installmentSelected: null,
      quantityInstallmentsSelected: { text: '1x', value: 1 },
      flagSelected: null,
      payments: [],
      flags: [],
      selected: {},
      aut: '',
      value: 0,
      dialogConfirmation: false,
      dialogPaymentsCreditLetter: false,
      keyPaymentsCreditLetter: 0,
      propsDialogConfirmation: {
        type: 'CancelSale',
        title: 'Cancelar Venda',
        message: 'Deseja Realmente Cancelar a Vendass?',
        action: 'resetSale',
      },
      paidDate: getCurrentPeriod().currentDateEN,
      creditLetter: {
        number: null,
        value: null
      },
    };
  },
  created() {
    window.addEventListener('keydown', this.actionListener);
  },
  destroyed() {
    window.removeEventListener('keydown', this.actionListener);
  },
  computed: {
    headers () {
      return [
        { text: 'Forma Pagamento', value: 'payment_method' },
        { text: 'Qtd. Parcelas', value: 'quantity_installments' },
        { text: 'Bandeira', value: 'flag' },
        { text: 'Cod. Aut.', value: 'aut',},
        { text: 'Valor', value: 'value', align: 'end' },
        { text: '', value: 'actions', align: 'end' },
      ];
    },
    setReadOnly () {
      if (!this.paymentSelected) {
        return false;
      }

      return !(this.paymentSelected.code === 'credit_card' ||
        this.paymentSelected.code === 'debit_card') || false;
    },
    subTotal () {
      return formatCurrency(this.model.total_products);
    },
    discount () {
      return formatCurrency(this.model.total_discount_value);
    },
    finalTotal () {
      return formatCurrency(this.model.total_order);
    },
    paidTotal () {
      const paidTotal = this.payments.reduce((value, total) => {
        return value += parseFloat(total.value);
      }, 0);

      return paidTotal.toFixed(2);
    },
    openedValue() {
      const openValue = (this.model.total_order - this.paidTotal).toFixed(2);

      return openValue < 0 ? 0 : openValue;
    },
    changeValue() {
      const openValue = (this.model.total_order - this.paidTotal).toFixed(2);

      return openValue < 0 ? Math.abs(openValue) : 0;
    },
    notCanFinish () {
      return this.setNotCanFinish();
    },
    setFieldsRequired () {
      if (!this.paymentSelected) {
        return [];
      }

      return this.paymentSelected.code === 'credit_card' ||
        this.paymentSelected.code === 'debit_card' ? [this.rules.required] : [];
    },
    quantityInstallments () {
      return [
        { text: '1x', value: 1 },
        { text: '2x', value: 2 },
        { text: '3x', value: 3 },
        { text: '4x', value: 4 },
        { text: '5x', value: 5 },
        { text: '6x', value: 6 },
        { text: '7x', value: 7 },
        { text: '8x', value: 8 },
        { text: '9x', value: 9 },
        { text: '10x', value: 10 },
        { text: '11x', value: 11 },
        { text: '12x', value: 12 },
        { text: '13x', value: 13 },
        { text: '14x', value: 14 },
        { text: '15x', value: 15 },
        { text: '16x', value: 16 },
        { text: '17x', value: 17 },
        { text: '18x', value: 18 },
        { text: '19x', value: 19 },
        { text: '20x', value: 20 },
        { text: '21x', value: 21 },
        { text: '22x', value: 22 },
        { text: '23x', value: 23 },
        { text: '24x', value: 24 },
      ];
    }
  },
  methods: {
    async getPaymentMethods () {
      this.$api.allTypes.filters({ url: 'filters?domain=all_type&filter[type]=payment-method&page=1&per_page=-1' }).then(({ data: { all_types } }) => {
        this.paymentMethods = all_types.map((item) => {
          return {
            text: item.name,
            value: item.id,
            code: item.value
          };
        });

        if (this.model.items_replacement.length > 0) {
          this.changePaymentMethodReplacement();
        }
      });

      if (this.model.type === this.$enums.typePageOrders.ORDER_SALE_DEVOLUTION) {
        this.paymentMethods = this.paymentMethods.filter((item) => {
          return item.code === this.$enums.typePaymentMethod.LETTER_CREDIT || item.code === this.$enums.typePaymentMethod.CASH;
        });
      }
    },
    async getInstallments () {
      this.$api.typeInstallments.index().then(({ data: { type_installments } }) => {
        this.installments = type_installments.map((item) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
      });
    },
    async getFlags () {
      this.$api.allTypes.filters({ url: 'filters?domain=all_type&filter[type]=flag' }).then(({ data: { all_types } }) => {
        this.flags = all_types.map((item) => {
          return {
            text: item.name,
            value: item.id
          };
        });
      });
    },
    addPayment() {
      if (!this.$refs.pdvValidForm.validate()) {
        this.value = 0;

        return false;
      }

      if (this.value > 0) {
        if (this.value > 0 && !this.paymentSelected.value) {
          this.$noty.error('Selecionar uma forma de pagamento');
          this.resetForm();

          return false;
        }

        this.payments.push({
          payment_method: {
            id: this.paymentSelected.value,
            text: `${this.paymentSelected.text} ${this.creditLetter.number ? this.creditLetter.number  : ''}`,
            code: this.paymentSelected.code
          },
          quantity_installments: this.quantityInstallmentsSelected.value,
          flag: {
            ...(this.paymentSelected.code === 'credit_card' || this.paymentSelected.code === 'debit_card') && {
              id: this.flagSelected.value,
              text: this.flagSelected.text,
            },
          },
          aut: this.aut,
          value: this.value,
          paid_date: this.paidDate,
          ...this.creditLetter && {
            credit_letter_id: this.creditLetter.id,
          }
        });
        this.resetForm();
      }
    },
    setNotCanFinish() {
      return (this.openedValue > 0 && !this.permittedPartialPayment) || (this.permittedPartialPayment && this.paidTotal == 0) || false;
    },
    removePayment ({ index }) {
      this.payments.splice (index, 1);
    },
    resetForm () {
      this.value = 0;
      this.paymentSelected = null;
      this.flagSelected = null;
      this.aut = '';
      this.creditLetter.number = null;
      this.creditLetter.value = null;
      // this.$refs.pdvValidForm.reset();
      this.$refs.pdvValidForm.resetValidation();
      this.quantityInstallmentsSelected = { text: '1x', value: 1 };
      this.paidDate = getCurrentPeriod().currentDateEN;
    },
    setSelected (item) {
      this.selected = item[0];
    },
    confirm () {
      this.dialogConfirmation = true;
    },
    finishSale () {
      if (this.dialogConfirmation) {
        this.$emit('confirmDialog', { action: 'confirmFinishSale', data: { payments: this.payments } });
      }
      this.resetForm();
      this.dialogConfirmation = false;
    },
    paymentMethodPaid (paymentMethod) {
      if (this.payments.length) {
        let payment = this.payments.filter((item) => {
          return paymentMethod.code === item.payment_method.code;
        });

        if (payment.length) {
          return payment[0].value || formatCurrency(0);
        }

        return formatCurrency(0);
      }

      return formatCurrency(0);
    },
    actionListener(event) {
      if (event.key === 'Enter' && event.ctrlKey && !this.setNotCanFinish()) {
        this.confirm();
      }
    },
    closePayments () {
      this.resetForm();
      this.$emit('update:dialogModal', false);
    },
    changePaymentMethod(item) {
      if (this.$enums.typePaymentMethod.LETTER_CREDIT === item.code &&
        (this.$enums.orderTypes.ORDER_SALE === this.model.type ||
          this.$enums.accountTypes.ACCOUNT_RECEIVABLE === this.model.type)) {
        this.keyPaymentsCreditLetter = randomNumber();
        this.dialogPaymentsCreditLetter = true;
      } else {
        this.creditLetter = {
          number: null,
          value: null,
        };
      }
    },
    confirmCreditLetter(data) {
      this.creditLetter = {
        id: data.id,
        number: data.number,
        value: data.value_formatted,
        balance: data.balance_formatted
      };
      this.dialogPaymentsCreditLetter = false;
    },
    cancelCreditLetter() {
      this.paymentSelected = null;
      this.dialogPaymentsCreditLetter = false;
    },
    changePaymentMethodReplacement() {
      const paymentMethod = this.paymentMethods.find((item) => item.code === this.$enums.typePaymentMethod.LETTER_CREDIT);
      const value = this.model.total_value_replacement > this.model.total_order ? this.model.total_order : this.model.total_value_replacement;

      this.payments.push({
        payment_method: {
          id: paymentMethod.value,
          text: paymentMethod.text,
          code: paymentMethod.code
        },
        quantity_installments: 1,
        flag: {},
        aut: this.aut,
        value,
        paid_date: this.paidDate,
        total_value_replacement: this.model.total_value_replacement,
        is_replacement: true,
        no_remove: true
      });
      this.resetForm();
    }
  },
  async mounted() {
    await this.getPaymentMethods();
    await this.getInstallments();
    await this.getFlags();

    this.paymentSelected = {
      text: this.model?.payment_method?.text,
      value: this.model?.payment_method?.value,
      code: this.model?.payment_method?.code,
    };

    if (this.paymentSelected.code === 'letter_credit') {
      this.changePaymentMethod(this.paymentSelected);
    }
  }
};
</script>
