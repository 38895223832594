<template>
  <div>
    <ExpansionPanel v-model="panel" :title="'Desconto'" :icon="$icons.box" readonly>
      <v-form v-model="validFormFreight" ref="validFormFreight" lazy-validation>
        <Row>
          <Col cols="12" xs="12" sm="12" :md="3" :lg="3">
            <TextFieldMoney v-model="order.total_discount_value" :placeholder="'Desconto Geral'"
              :label="'Desconto Geral'" @input="updateInfosOrderSale"
              :readonly="true" />
          </Col>
          <Col cols="12" xs="12" sm="12" :md="9" :lg="9">
            <Button label="Desconto Geral" color="primary" rounded
              @click="openDialog('dialogOrderEditModalModal')" :disabled="disabledButtons" />
            <Button label="Zerar descontos" color="secondary" rounded
              @click="openDialog('dialogConfirmation')" class="ml-2" :disabled="disabledButtons" />
          </Col>
        </Row>
      </v-form>
    </ExpansionPanel>

    <Dialog :dialog="dialogOrderEditModalModal" :max-width="600">
      <OrderDiscountEditModal slot="content" @changeDiscount="changeDiscount"
      @close="dialogOrderEditModalModal = false" />
    </Dialog>

    <DialogConfirmation :dialog="dialogConfirmation"
      title="Zerar descontos"
      message="Deseja realmente zerar todos os descontos?"
      @noActionDialogConfirmation="dialogConfirmation = false"
      @yesActionDialogConfirmation="resetDiscount" />
  </div>
</template>

<script>
import {
  Row,
  Col,
  ExpansionPanel,
  TextFieldMoney,
  Button,
  Dialog
} from '@/components/vuetify';
import DialogConfirmation from '@/components/DialogConfirmation';
import { required } from '@/utils/rules';
import UpdateInfosOrderSaleMixin from '../mixins/UpdateInfosOrderSaleMixin';
import OrderDiscountEditModal from './OrderDiscountEditModal.vue';

export default {
  name: 'OrderFreight',
  components: {
    ExpansionPanel,
    Row,
    Col,
    TextFieldMoney,
    Button,
    OrderDiscountEditModal,
    Dialog,
    DialogConfirmation
  },
  inject: ['propsComponents'],
  mixins: [UpdateInfosOrderSaleMixin],
  data () {
    return {
      panel: [0],
      validFormFreight: false,
      rules: {
        required: required
      },
      dialogOrderEditModalModal: false,
      dialogConfirmation: false,
    };
  },
  computed: {
    total_value_individual_discount() {
      return this.order.items.filter(item => item.is_individual_discount).reduce((acc, item) => acc + parseFloat(item.discount_value), 0);
    },
    disabledButtons() {
      return this.order.is_delivered;
    },
  },
  methods: {
    openDialog(dialog) {
      this[dialog] = true;
    },
    changeDiscount(discount) {
      this.order.total_discount_value = parseFloat(discount);
      this.order.total_order = parseFloat(this.order.total_products) - parseFloat(discount);

      this.updateInfosOrderSale();
      this.dialogOrderEditModalModal = false;
    },
    resetDiscount() {
      this.order.total_discount_value = 0;
      this.order.total_order = parseFloat(this.order.total_products);

      this.dialogConfirmation = false;
      this.updateInfosOrderSale();
    }
  }
};
</script>
