<script>
import { typePage } from '@/utils/enums';

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      order: this.model,
      typePage: typePage
    };
  },
  computed: {
    isDelivered () {
      return this.order.is_delivered;
    }
  },
  watch: {
    model: {
      handler() {
        //TODO está sendo chamado várias vezes
        this.order = this.model;
      },
      deep: true,
    }
  },
  methods: {
    changeSelects (type, key) {
      this.order[key] = this.order[type]?.value || null;
    },
    changeAutoComplete (itemSelected, type, key) {
      this.order[key] = itemSelected.id || '';
      this.order[type] = itemSelected;

      if (type === this.$enums.typeRegister.CLIENT) {
        this.order.is_consumer_final = itemSelected.taxpayer_type === 'non_taxpayer' || false;

        let notState = true;
        this.propsComponents.natureOperations.forEach(({ item }) => {
          item.states.forEach((state) => {
            if (itemSelected.address.code_state == state.code_state) {
              notState = false;
            }
          });
        });

        if (notState) {
          this.$noty.error('O cliente selecionado não possui natureza de operação para a sua UF.');
          this.order[key] = '';
          this.order[type] = {};
        }
      }
    },
    updateInfosOrderSale () {
      this.order.total_weight_liquid = 0;
      this.order.total_weight_gross = 0;
      this.order.total_products = 0;
      this.order.total_value_replacement = 0;

      this.order.items.forEach((item, index) => {
        item.item_number = index + 1;
        this.order.total_products += parseFloat(item.unit_value * item.quantity);
        this.order.total_weight_liquid += parseFloat(item.total_weight_liquid);
        this.order.total_weight_gross += parseFloat(item.total_weight_gross);
      });

      this.order.items_replacement.forEach((item, index) => {
        item.item_number = index + 1;
        this.order.total_value_replacement += parseFloat(item.total_value);
      });

      this.order.total_order = (parseFloat(this.order.total_products) - parseFloat(this.order.total_discount_value)).toFixed(2);

      if (this.order.include_total_in_freight) {
        this.order.total_order =
          (parseFloat(this.order.total_order)
          + parseFloat(this.order.total_freight)
          + parseFloat(this.order.total_expense)).toFixed(2);
      }
    },
    resetTransporter () {
      this.order.transporter = {
        brand: null,
        specie: null,
        cte: null,
        vehicle_id: null,
        uf: null,
        rntc: null,
        plate: null,
        register_id: null,
        quantity: 0,
      };
    },
  }
};
</script>
